import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const ConfigurationSettings = () => {
  return (
    <LayoutComponent>
      <h2>Configuration Settings</h2>

      <div className="testimonials">
        <h5>Note</h5>
        <p>
          You must be an organization admin or project admin to manage the
          configuration settings for your project. Team members do not have
          access to modify these settings.
        </p>
      </div>

      <h6>Accessing Configuration Settings</h6>
      <ol>
        <li>
          Navigate to the project for which you want to modify configurations.
        </li>
        <li>
          In the project sidebar, click on <strong>Configuration</strong> to
          access the configuration settings page.
        </li>
      </ol>

      <p>
        Once in the configuration settings, you can customize various aspects of
        the project.
      </p>

      <div className="testimonials">
        <h5>Note</h5>
        <p>
          Some default configuration values are added automatically on project
          creation and cannot be edited or deleted.
        </p>
      </div>

      <h6>Managing Existing Configurations</h6>
      <p>
        After adding configurations, you can manage them by editing or deleting
        entries from the list. Simply locate the configuration item and use the
        available actions.
      </p>
    </LayoutComponent>
  )
}

export default ConfigurationSettings
